<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field has-addons">
            <input class="is-checkradio"
              checked
              id="selectDates"
              type="radio"
              name="selectDateType"
              value="selectDates"
              v-model="innerValue.selectDateType">
            <label for="selectDates">Select Dates</label>
            <input class="is-checkradio"
              id="allDates"
              type="radio"
              name="selectDateType"
              value="allDates"
              v-model="innerValue.selectDateType">
            <label for="allDates">All Dates</label>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Sales Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Sales Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-model="selectedClient"
                placeholder="Select Client"
                track-by="assetId"
                label="assetName"
                :options="clients"
                :options-limit="clientFilter.pageSize"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                :loading="isClientLoading"
                :internal-search="false"
                @select="selectClient"
                @remove="removeClient"
                @search-change="getClientDropdownFilter">
                <span class="has-text-danger"
                  slot="noResult">Client not found.</span>
              </multiselect>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>

    </aside>
  </app-side-menu>

</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import _debounce from 'lodash.debounce'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import SalesAnalysisService from './SalesAnalysisService'

export default {
  name: 'SalesAnalysisListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu,
    Multiselect
  },
  directives: {
    mask
  },
  mixins: [],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectDateType: 'selectDates',
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      isClientLoading: false,
      selectedClient: null,
      clients: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      clientFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal) {
        this.selectDateType = 'selectDates'
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = ''
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = ''
      }
    },
    'innerValue.selectDateType': function (newVal, oldVal) {
      // if (newVal === 'selectDates') {
      //   this.selectedFromDate = new Date ((new Date()).setMonth((new Date().getMonth()-1)))
      //   this.selectedToDate = new Date()
      //   this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      //   this.innerValue.dateTo = this.selectedToDate.toISOString()
      // } else {
      //   this.selectedFromDate = null
      //   this.selectedToDate = null
      //   this.innerValue.dateFrom = ''
      //   this.innerValue.dateTo = ''
      // }
      if (newVal === 'allDates') {
        this.selectedFromDate = null
        this.selectedToDate = null
        this.innerValue.dateFrom = ''
        this.innerValue.dateTo = ''
      } else {
        this.innerValue.dateTo = new Date().toISOString()
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = this.value
    //   },
    //   deep: true
    // }
  },
  async created() {
    if (this.innerValue.dateFrom) {
      this.selectedFromDate = new Date(this.innerValue.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.selectedToDate = new Date(this.innerValue.dateTo)
    }
    console.log(this.innerValue)
    // if (this.selectDateType === 'selectDates') {
    //   this.selectedFromDate = new Date ((new Date()).setMonth((new Date().getMonth()-1)))
    //   this.selectedToDate = new Date()
    //   this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
    //   this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
    // }
    // if (this.selectDateType === 'allDates') {
    //   this.innerValue.dateFrom = ''
    //   this.innerValue.dateTo = ''
    // }
    this.getClientDropdown()
    this.clients = await this.getClientDropdown()
    this.selectedClient = this.clients[0]
  },
  mounted() {},
  beforeDestroy() {
    const elements = document.getElementsByClassName('datepicker')
    Array.from(elements).forEach((element, index, array) => {
      const parent = element.parentNode
      if (parent.nodeName === 'BODY') {
        parent.removeChild(element)
      }
    })
  },
  methods: {
    onFilterClick() {
      if (this.selectDateType === 'selectDates') {
        this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
        this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      }
      if (this.selectDateType === 'allDates') {
        this.innerValue.dateFrom = ''
        this.innerValue.dateTo = ''
      }
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
      this.selectedToDate = new Date()
      this.selectedClient = this.clients[0]
      this.selectDateType = this.innerValue.selectDateType = 'selectDates'
      this.$emit('reset', this.selectedFromDate, this.selectedToDate)
    },
    selectClient(selected) {
      this.innerValue.assetId = selected.assetId
      this.innerValue.clientName = selected.assetName
    },
    removeClient() {
      this.innerValue.assetId = ''
      this.innerValue.clientName = ''
    },
    async getClientDropdown() {
      this.isClientLoading = true
      this.clients = await SalesAnalysisService.getClientDropdownProto(this.clientFilter)
      this.clients.unshift({ assetName: 'All Clients', assetId: '' })
      this.selectedClient = this.clients.find((c) => c.assetId === this.clientFilter.assetId)
      this.isClientLoading = false
      return this.clients
    },
    getClientDropdownFilter: _debounce(async function (query) {
      this.isClientLoading = true
      this.clientFilter.assetName = query
      this.clients = await SalesAnalysisService.getClientDropdownProto(this.clientFilter)
      if (this.clientFilter.assetName === '') {
        this.clients.unshift({ assetName: 'All Clients', assetId: '' })
      }
      this.isClientLoading = false
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
